import React from "react";
import "@assets/scss/App.scss";
import loadable from '@loadable/component'
import Layout from "@components/layout";
import Header from "@components/header/Header";
import ContentHeaderImageHome from "@components/modules/content-header-image/content-header-image-home";
import SEO from "@components/seo";
import FloatingBtn from "@components/floating-book-btn/";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const ModuleHomeRender = loadable(() => import('@components/modules/module-render/module-home'))
const Footer = loadable(() => import('@components/footer/'))
const PopularSearch = loadable(() => import('@components/popular-search/index'))

const HomeTemplate = ({ pageContext, data, location }, props) => {
    let headerClass;
    if(data.strapiPages.Pagetype != "Without_Banner"){
        if (data.strapiPages.BannerImage[0].headerType === 'HeaderImageLinks') {
            headerClass = " header-transparent header-transparent-cta";
        } else if (data.strapiPages.BannerImage[0].headerType === 'HeaderImageButtons') {
            headerClass = " header-transparent";
        } else {
            headerClass = "";
        }
    }

    const templateBreadcrumbs = {
        childSlug: data.strapiPages.Slug,
        childTitle: data.strapiPages.Pagename,
        parentSlug: data.strapiPages.Parent ? data.strapiPages.Parent.Slug : false,
        parentTitle: data.strapiPages.Parent ? data.strapiPages.Parent.Pagename : false
    }
    // Set up the array of image data and `media` keys.
    // You can have as many entries as you'd like.
        const sources = [
            data.strapiPages.Pagetype != "Without_Banner" ? data.strapiPages.BannerImage[0].mobileImage.childImageSharp.fluid : '',
            {
                ...data.strapiPages.Pagetype != "Without_Banner"? data.strapiPages.BannerImage[0].desktopImage.childImageSharp.fluid : '',
                media: `(min-width: 1024px)`,
            },
        ]
         
    return (
        <Layout>
            <SEO
                title={data.strapiPages.MetadataContent ? data.strapiPages.MetadataContent.MetaTitle : data.strapiPages.Title}
                description={data.strapiPages.MetadataContent ? data.strapiPages.MetadataContent.MetaDescription : null}
                image={data.strapiPages.MetadataContent && data.strapiPages.MetadataContent.MetaImage ? data.strapiPages.MetadataContent.MetaImage.childImageSharp.resize : null}
            />
            <Helmet>
                <link rel="preconnect" href="https://recaptcha.net" crossorigin/>
                <link rel="preconnect" href="https://www.googletagmanager.com" crossorigin/>
                <link rel="preconnect" href="https://www.gstatic.com" crossorigin/>
                <link rel="preconnect" href="https://www.google-analytics.com" crossorigin/>
                <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin/>
                <link rel="preconnect" href="https://www.kerfuffle.com/" crossorigin/>


            {/* <link data-react-helmet="true" rel="preload" as="script" crossorigin="anonymous" href={ "https://www.kerfuffle.com/media/jlexreview/assets/script.js" }></link> */}
            {/* <link data-react-helmet="true" rel="preload" as="script" crossorigin="anonymous" href={ "https://www.googletagmanager.com/gtm.js?id=GTM-K7J9KFP" }></link> */}
            <link data-react-helmet="true" rel="preload" as="font" crossorigin="anonymous" type="font/woff2" href={ "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2" }></link>
            <link data-react-helmet="true" rel="preload" as="font" crossorigin="anonymous" href={ " https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2" }></link>
                {/* <link data-react-helmet="true" rel="preload" as="font" crossorigin="anonymous" type="font/woff2" href={ "https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2" }></link>
                <link data-react-helmet="true" rel="preload" as="font" crossorigin="anonymous" type="font/woff2" href={ "https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2" }></link>
                <link data-react-helmet="true" rel="preload" as="font" crossorigin="anonymous" type="font/woff2" href={ "https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2" }></link>                 */}
            </Helmet>

            <Header classNameHeader={headerClass} />
                  <>
                    {(data.strapiPages.BannerImage[0].headerType === 'HeaderImageLinks') &&
                        <ContentHeaderImageHome
                            Title={data.strapiPages.Title}
                            // imgUrl={data.strapiPages.BannerImage[0].Image.childImageSharp.fluid}
                            imgUrl={sources}
                            ButtonData={data.strapiPages.BannerImage[0].Button}
                            pageArray={templateBreadcrumbs}
                            pageName_1="Employers"
                            pageLink_1="#"
                            lastClass_1="last"
                            HideBreadcrumbs={data.strapiPages.BannerImage[0].HideBreadcrumbs}
                            desktopImageAlign={data.strapiPages.BannerImage[0].desktopImageAlign}
                            tabletImageAlign={data.strapiPages.BannerImage[0].tabletImageAlign}
                            mobileImageAlign={data.strapiPages.BannerImage[0].mobileImageAlign}
                        />
                    }                                    
                    {data.strapiPages.selectModules &&
                        <ModuleHomeRender
                            moduleData={data.strapiPages.selectModules} pageLocation={location}
                        />
                    }
                </>
            
            <PopularSearch {...props} />
            <Footer />
            <FloatingBtn />
        </Layout>
    );
}

export default HomeTemplate

export const query = graphql`
query MyQuery1($slug: String!) {
    strapiPages(Slug: {eq: $slug}, Published: {eq: true}) {
        id
        Pagename
        Pagetype
        Slug
        Parent {
            id
            Slug
            Pagename
        }
        Title
        MetaTitle
        MetaDescription
        BannerImage {
            id
            mobileImageAlign
            tabletImageAlign
            desktopImageAlign
            headerType
            HideBreadcrumbs
            Button {
                id
                Label
                targetLink
                externalLink
                page {
                    Slug
                }
            }
          
            mobileImage: Image {
                childImageSharp {

                    fluid(quality: 100, toFormat: JPG, maxWidth: 768, cropFocus: ATTENTION, fit: INSIDE, background: "#fff") {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktopImage: Image {
                childImageSharp {
                    fluid(quality: 100, toFormat: JPG, maxWidth: 1570, maxHeight: 582, cropFocus: ATTENTION, fit: INSIDE, background: "#fff") {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }            
        }
        articleText {
            Title
            articleText
        }
        sidebar {
            hideSaveBtn
            hideModuleBg
            sidebarButtons {
                Label
                id
                targetLink
                externalLink
                page {
                    Slug
                }
            }
            sidebarVideo {
                id
                showVideo
                videoask
                videoChannel
                videoId
                videoTitle
                posterImage {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        teams {
            id
            CalendlyLink
            BookVideoLabel
            Image {
                childImageSharp {
                    fixed(width: 70, height: 70) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            Name
            PhoneNumber
        }
        selectModules {
            id
            modules {
                id
                moduleName
                component
               
                sectionClass
                moduleType {
                    id
                    Title
                    Text
                    showTitle
                    moduleClass
                    Video {
                        showVideo
                        videoChannel
                        videoId
                        videoask
                    }
                    Show_Mentors
                    Btn_CTA_1_Label
                    Btn_CTA_2_Label
                    Btn_CTA_1_Url
                    Btn_CTA_2_Url
                    Buttons {
                        id
                        Label
                        targetLink
                        externalLink
                        page {
                            Slug
                        }
                    }
                   Accordion {
                        id
                        SectionTitle
                        displayAccordion
                        AccordionFields {
                            Content
                            Title
                            id
                        }
                    }
                    Image {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 620) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }

                    Subscription_Table_Col {
                        title
                        text
                        id
                        btnLink
                        btnLabel
                        btnClass                        
                    }
                    Subscription_Bottom_Text
                }
            }
        }
        MetadataContent {
            MetaDescription
            MetaTitle
            MetaImage {
                childImageSharp {
                    resize(width: 1200) {
                        src
                        height
                        width
                    }
                }
            }
        }
    }
}
`