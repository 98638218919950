import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from "gatsby";

import ShareIcon from '@icons/share.inline.svg';
import FacebookIcon from '@icons/facebook.inline.svg';
import TwitterIcon from '@icons/twitter.inline.svg';
import LinkedInIcon from '@icons/linkedIn.inline.svg';
import EmailIcon from '@icons/email.inline.svg';
// import InstagramIcon from '@icons/instagram.inline.svg';

function stripTrailingSlash(str) {
    if(str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
    }
    return str;
}

export default function ShareDropdown(props) {
    const pageTitle     = props.pageTitle ? encodeURI(props.pageTitle + ' | Crux careers') : '';
    const pageTitleMail = props.pageTitle ? props.pageTitle : '';
    const pageUrl       = props.pageLocation ? stripTrailingSlash(props.siteURL + props.pageLocation.pathname) : '';
    const viaShare      = 'cruxcareers';
    const twitterUrl    = `https://twitter.com/share?text=${pageTitle}&url=${pageUrl}`;
    const facebookUrl   = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}&t=${pageTitle}`;
    const linkedIn      = `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${pageTitle}`;
    const email         = `/share-to-friend`;
    const btnState      = { title: pageTitleMail, url: pageUrl }

    return (
        <Dropdown className="social-share px-15 px-sm-25">
            <Dropdown.Toggle variant="none">
                {!props.hideShareIcon &&
                    <ShareIcon />
                }
                {props.btnLabel &&
                    props.btnLabel
                }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href={facebookUrl} target="_blank" rel="noreferrer"><FacebookIcon /> Facebook</Dropdown.Item>
                <Dropdown.Item href={twitterUrl} target="_blank" rel="noreferrer"><TwitterIcon /> Twitter</Dropdown.Item>
                <Dropdown.Item href={linkedIn} target="_blank" rel="noreferrer"><LinkedInIcon /> LinkedIn</Dropdown.Item>
                { props.sharePage && props.sharePage !== "blog" && (
                    <Link className="dropdown-item" to={email} state={btnState ? btnState : null}><EmailIcon /> Share to friend</Link>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}