import React from 'react';
import PropTypes from 'prop-types';
import useSiteMetadata from '@hooks/use-site-metadata';

import  "./assets/_index.scss";

import ShareDropdown from"./ShareDropdown";
import ShareList from"./ShareList";

export default function SocialShare(props) {
    const { siteURL } = useSiteMetadata();
    function selectDisplayStyle(params) {
        switch(params) {
            case true:
                return <ShareList
                    parentClass="social-share-list mt-34 mt-md-51 mt-xl-45"
                    hideShareIcon={props.hideShareIcon}
                    btnLabel={props.btnLabel}
                    siteURL={siteURL}
                    pageLocation={props.pageLocation ? props.pageLocation : null}
                    pageTitle={props.pageTitle ? props.pageTitle : null}
                    sharePage={props.sharePage}
                />;
            default:
                return <ShareDropdown
                    hideShareIcon={props.hideShareIcon}
                    btnLabel={props.btnLabel}
                    siteURL={siteURL}
                    pageLocation={props.pageLocation ? props.pageLocation : null}
                    pageTitle={props.pageTitle ? props.pageTitle : null}
                    sharePage={props.sharePage}
                />;
        }
    }
    return (
        <>
            {selectDisplayStyle(props.showAsList)}
        </>
    );
}

// Check all the prop types
SocialShare.propTypes = {
    showAsList: PropTypes.bool,
    hideShareIcon: PropTypes.bool,
    btnLabel: PropTypes.string
};

// Specifies the default values for props:
SocialShare.defaultProps = {
    showAsList: false,
    hideShareIcon: false,
    btnLabel: 'Share',
    pageLocation: ''
};