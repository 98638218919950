import React from 'react';
import { Link } from "gatsby";

import ShareIcon from '@icons/share.inline.svg';
import FacebookIcon from '@icons/facebook.inline.svg';
import TwitterIcon from '@icons/twitter.inline.svg';
import LinkedInIcon from '@icons/linkedIn.inline.svg';
import EmailIcon from '@icons/email.inline.svg';
// import InstagramIcon from '@icons/instagram.inline.svg';

function stripTrailingSlash(str) {
    if(str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
    }
    return str;
}

export default function ShareDropdown(props) {
    const pageTitle     = props.pageTitle ? encodeURI(props.pageTitle + ' | Crux careers') : '';
    const pageTitleMail = props.pageTitle ? props.pageTitle : '';
    const pageUrl       = props.pageLocation ? stripTrailingSlash(props.siteURL + props.pageLocation.pathname) : '';
    const viaShare      = 'cruxcareers';
    const twitterUrl    = `https://twitter.com/share?text=${pageTitle}&url=${pageUrl}`;
    const facebookUrl   = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}&t=${pageTitle}`;
    const linkedIn      = `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${pageTitle}`;
    const email         = `/share-to-friend`;
    const btnState      = { title: pageTitleMail, url: pageUrl }

    return (
        <div className={props.parentClass}>
                {!props.hideShareIcon &&
                    <ShareIcon />
                }
                {props.btnLabel &&
                    <span className="social-share-list__label">{props.btnLabel}</span>
                }
                <ul className="list-inline social-share-list__list mb-0 mt-22">
                    <li className="list-inline-item"><a href={facebookUrl} aria-label="share on Facebook" target="_blank" rel="noreferrer"><FacebookIcon /></a></li>
                    <li className="list-inline-item"><a href={twitterUrl} aria-label="share on Twitter" target="_blank" rel="noreferrer"><TwitterIcon /></a></li>
                    <li className="list-inline-item"><a href={linkedIn} aria-label="share on LinkedIn" target="_blank" rel="noreferrer"><LinkedInIcon /></a></li>
                    {
                        props.sharePage && props.sharePage !== "blog" && (
                            <li className="list-inline-item"><Link to={email} state={btnState ? btnState : null}><EmailIcon /></Link></li>
                        )
                    }
                    {/* <li className="list-inline-item"><a href="#" aria-label="share on Instagram" target="_blank" rel="noreferrer"><InstagramIcon /></a></li> */}
                </ul>
        </div>
    );
}